<template>
    <div class="formNav-comp">
        <div @click="prev" class="nav-button button-up">
            <Icon name="arrow-up" />
        </div>
        <div @click="next" class="nav-button button-down">
            <Icon name="arrow-down" />
        </div>
    </div>
</template>

<script>
import Icon from './Icon.vue'
    export default {
        name: "FormNav",
        components: { Icon },

        methods: {
            prev() {
                this.$emit("changeQuestion", "prev")
            },
            next() {
                this.$emit("changeQuestion", "next")
            }
        },
    }
</script>

<style lang="sass" scoped>
.formNav-comp
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.nav-button
    font-size: $fzMd
    border-radius: 50px
    margin: $mgXxs
    padding: $mgXs
    cursor: pointer

    &:hover
        color: $cBrand
        background: rgba($cBrandA, 15%)

</style>