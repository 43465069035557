<template>
    <div v-if="data" class="endForm-comp" :data-active="show" :data-showPromo="showPromo">                
        <div v-if="isRedirectFirst" class="redirect-placeholder">
            <div v-if="demo" class="">
                <h4 class="h3 mb8">Redirecionamento</h4>
                <p class="redirect-label mb32">Fora do modo visualização, ao concluir o visitante será redirecionado automaticamente para:</p>
                <a :href="data.options.endForm.redirect" target="_blank" class="redirect-link"><Icon name="link" class="mr8" /> {{data.options.endForm.redirect}}</a>
            </div>
        </div>


        <!-- Thank you message -->
        <div v-else class="sent-message">
            <div class="message-container">
                
                <!-- Icon/Score -->
                <div v-if="data.options.endForm.showScore" class="score-block mb16">
                    <div class="score-value mb4">{{showScore}}</div>
                    <div class="score-label">pontos</div>
                </div>

                <div v-else class="sent-icon mb16"><Icon type="solid" name="paper-plane" /></div>

                <QuestionTitle class="message-title" :value="data.value" :variables="variables" :key="'title-' + data.slug" />
                <p v-if="data.description" class="message-description mt8">{{data.description[0]}}</p>
                <div class="button-action mt16">
                    <Btn v-if="data.options.endForm.type == 'redirect' && data.options.endForm.redirect" :href="data.options.endForm.redirect" @click.native="endForm('redirect')" target="_parent" layout="big" :formStyle="$parent.form.style"><Icon name="check" /> {{cta}}</Btn>
                    <Btn v-if="data.options.endForm.type == 'file' && data.options.endForm.file" :href="$api + 'file/download?asset=' + data.options.endForm.file.path" @click.native="endForm('file')" target="_self" layout="big" :formStyle="$parent.form.style"><Icon name="check" /> {{cta}}</Btn>
                </div>
            </div>
        </div>

        <!-- Promo Message -->
        <div class="promo-message">
            <div class="logo">
                <img src="@/assets/img/home/respondi-logo.png" alt="Logo Respondi.app" />
            </div>
            <div class="content">
                <div class="image">
                    <img src="@/assets/img/home/respondi-forms-sign-up.jpg" alt="Crie sua conta" class="image-file">
                </div>
                <div class="text">
                    <h1 class="title mb8">Crie formulários incríveis como este em 5 minutos.</h1>
                    <p class="description mb16">Tenha acesso a campos exclusivos como validação de CPF, CNPJ e busca por CEP.</p>
                    <Btn @click.native="trackRef" :href="'https://respondi.app/?utm_source=app&utm_medium=endform&utm_campaign=End%20Form&utm_term=' + $parent.form.cus">Criar conta grátis →</Btn>
                </div>
            </div>
            <div class="footer">
                <div v-if="demo" class="footer-text">
                     <router-link v-if="$user.plan =='free' || $user.plan == null" :to="{name: 'Upgrade'}" tag="div" class="callout">Assine um plano para remover essa mensagem <TagLabel type="new">Upgrade</TagLabel></router-link>
                     <router-link v-else :to="{name: 'EditorSettings', slug: form.slug}" tag="div" class="callout">Você pode desabilitar essa mensagem nas <span class="link"><Icon name="cog" /> Opões</span>.</router-link>
                </div>
                <div v-else class="footer-text"><img class="brasil-icon mr8" src="@/assets/img/home/brasil-icon.svg" alt="Bandeira do Brasil"> Feito com carinho no Brasil.</div>
            </div>
        </div>
    </div>
</template>

<script>
import QuestionTitle from '../preview/QuestionTitle.vue'
import TagLabel from '../TagLabel.vue'
import Btn from './Btn.vue'
    export default {
    components: { Btn, TagLabel, QuestionTitle },
        name: "EndForm",
        props: {
            data: Object,
            removePromo: Boolean,
            demo: Boolean,            
            active: {
                type: Boolean,
                default: false
            },
            variables: Object,
            score: Object
        },

        data() {
            return {
                show: false,
                showPromo: false,
                form: this.$parent.form
            }
        },

        computed: {
            showScore(){
                if(this.demo) return this.score.local

                // Validate if front-end and back-and scores match.
                // If not, redirect to 404 page. See: readme.md/#scores
                if(this.score.remote === this.score.local) return this.score.remote
                this.$router.push({name: "404"})
            },

            type(){
                return this.data.options.endForm.type
            },

            cta() {
                if(this.data.options.cta_end) return this.data.options.cta_end
                if(this.data.options.endForm.type == "redirect") return "Acessar"
                if(this.data.options.endForm.type == "file") return "Baixar arquivo"
            },

            isRedirectFirst(){
                if(this.data.options.endForm.type == 'redirect_first') return true
            },
           
        },

        watch: {
            active(value) {
                if(value && !this.demo && this.data.options.endForm.type == "redirect_first") window.top.location.href = this.data.options.endForm.redirect
                if(value) setTimeout(() => { this.show = true }, 300)                
                if(!this.removePromo) setTimeout(() => {
                    if(this.data.options.endForm.type != 'redirect_first') this.showPromo = true 
                }, 3000)                
            },
            
        },
       

        methods: {
            trackRef() {
                if(!this.demo) this.$trackEvent.mixpanel("End Form Reference", {distinct_id: this.$parent.form.cus, form_id: this.$parent.form.slug});
            },
            endForm(type){
                if(!this.demo) this.$trackEvent.mixpanel("End Form", {distinct_id: this.$parent.form.cus, form_id: this.$parent.form.slug, type: type});
            }
        },
    }
</script>

<style lang="sass" scoped>
.endForm-comp
    pointer-events: auto
    display: flex
    width: 100%
    height: 100vh
    background: transparent
    // background-color: $cFormBackground
    // background-image: $formBgImage
    opacity: 0
    transition: opacity .3s

    &[data-active="true"]
        transition: opacity .3s
        opacity: 1

    .redirect-placeholder
        display: flex
        align-items: center
        justify-content: center
        padding: $mgLg
        flex-direction: column
        width: 100%

        .h3 
            font-size: $fzMd
            font-weight: normal
            color: $cG5

        .redirect-label
            font-size: $fzSm
            color: $cG5
            text-align: center
            max-width: 400px
        
        .redirect-link
            +glassStyle($cPrimary)
            padding: $mgXs $mgSm
            border-radius: 4px
    

    .promo-message
        display: grid
        grid-template-rows: 60px auto 60px
        grid-template-areas: "logo" "content" "footer"
        background: #E8F1FE
        height: 100%
        width: 0%
        flex: 1
        opacity: 0
        color: $cBlack

        > div
            opacity: 0
        

        .logo
            padding: $mg $mg 0 0
            display: flex
            grid-area: logo
            justify-content: flex-end

        .content
            padding: $mg
            grid-area: content
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            text-align: center
            line-height: 140%

            > div
                max-width: 350px

            .title
                font-size: $fzMd
            
            .description
                font-size: $fzXs

            .image-file
                max-height: 300px


        .footer
            padding: $mgXs
            font-size: $fzXs
            grind-area: footer
            align-items: center
            text-align: center

            .footer-text
                display: flex
                align-items: center
                justify-content: center
                vertical-align: center

            .brasil-icon
                max-width: 18px

        .callout
            padding: $mgXs
            background: #fff
            color: $cG5
            border-radius: 5px
            display: inline-block
            cursor: pointer

    .message-container
        display: flex
        align-items: center
        flex-direction: column
        width: 350px

    .sent-message
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        height: 100%
        width: 100%
        font-size: $fzLg
        color: $cFormText

        .sent-icon
            font-size: $fzXxl

        .message-title
            font-size: $fzLg
            text-align: center
        
        .message-description
            font-size: $fzSm
            text-align: center
            white-space: pre-line

[data-showPromo="true"][data-active="true"]
    .sent-message
        width: 50%
        transition: all .5s

    .promo-message
        width: 50%   
        opacity: 1     
        transition: all 1.2s

        > div
            transition: all .3s
            opacity: 1



@media (max-width: 640px)
    .endForm-comp
        flex-direction: column

        .sent-message
            min-height: 100vh
            width: 100%

        .promo-message
            height: 100vh
            width: 100%

            .logo
                justify-content: center


    [data-showPromo="true"][data-active="true"]
        &.endForm-comp
            overflow: auto            

            .sent-message
                min-height: 50vh
                width: 100%
                opacity: 1

            .promo-message
                height: 100vh
                width: 100%
                opacity: 1           

                > div
                    opacity: 1

.score-block
    +glassStyle($cFormQuestion)
    color: rgba($cFormQuestion, 1)    
    padding: $mgSm $mg
    text-align: center
    border-radius: 5px

    .score-icon
        font-size: $fzSm
        opacity: .7

    .score-value
        font-size: $fzXxl
        
    .score-label
        font-weight: bold
        font-size: $fzXs
        text-transform: uppercase



</style>