<template>
    <div class="preview-container" :data-endform="endform">

        <!-- Header -->
        <div class="header-container" :data-logo-position="form.style.logo.position">
            <div class="progressbar">
                <ProgressBar :position="current_field + 1" :total="form.fields.length"/>
            </div>
            <div class="form-logo">
                <FormLogo v-if="form.style.logo.path" :src="form.style.logo.path" />
            </div>
        </div>

        <!-- Field -->
        <div class="fields-container">

            <!-- Fields -->
            <div v-for="(field, index) in form.fields" :key="field.slug" :class="{active: index == current_field, exit: index < current_field}" class="field-container-item">
                <Field 
                    :data="field" 
                    :variables="variables"
                    :isCurrent="current_field == index" 
                    @submit:value="submit" 
                    @new-variable="setVariable"
                    @endForm="endForm"
                    @update-score="updateScore"
                    @final-score="setFinalValidScore"
                    :key="'field-' + field.slug" 
                    ref="field"
                     />
            </div>

            <!-- Sent Message  -->
            <div class="end-form" data-type="endform">
                <EndForm :data="endFormData" :removePromo="form.style.removeBrand" :active="endform" :demo="demo" :variables="variables" :score="score" />
            </div>

        </div>

        <!-- Navbar -->
        <nav :class="{inative: endform}" class="nav-container">
            <FormNav @changeQuestion="changeQuestion"/>
        </nav>


        
    </div>
</template>

<script>
import EndForm from './form/EndForm.vue'
import ProgressBar from './ProgressBar.vue'
import FormLogo from './FormLogo.vue'
import FormNav from './FormNav.vue'
import Field from './Field.vue'
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin.js';
import { ValidateMixin } from '@/mixins/ValidateMixin.js';
import score from './preview/helpers/scoreHelper.js'

    export default {
    components: { Field, FormNav, FormLogo, ProgressBar, EndForm },
    mixins: [UtilitiesMixin, ValidateMixin],
    name: "Preview2",
    props: {
        form: Object,
        demo: Boolean,
    },

    data() {
        return {
            current_field: 0,            
            variables: {},
            endform: false,
            endFormData: null,
            isFirst: true,
            score: {
                local: 0,
                remote: 0
            },
            scoresList: {}
        }
    },  
    
    mounted () {
        this.watchKeyPress()
        this.trackAppsEvents()
    },

    methods: {
        setFinalValidScore(value){            
            this.score.remote = value // Valid score from API. See: readme.md/#scores
        },

        updateScore(value){  
            this.scoresList[value.questionSlug] = value.points
            const sumScores = score.sumAllPoints(this.scoresList)
            this.score.local = sumScores
        },

        endForm(data){  
            this.endFormData = data
            // this.sendNotification()
            this.endform = true
            localStorage.removeItem('uuid')
            this.current_field++
            

            if(!this.demo && this.hasAnyAnswer()){
                this.$trackEvent.mixpanel("New Respondent", {distinct_id: this.$parent.form.cus, form_id: this.$parent.form.slug});
                this.$trackEvent.gtm("New Respondent", {distinct_id: this.$parent.form.cus, form_id: this.$parent.form.slug});
                this.trackAppsEvents("EndForm")
            } 
        },


        // sendNotification(){
        //     if(this.demo || !this.hasAnyAnswer()) return null

        //     let notification = {
        //         form_id: this.form.slug,
        //         respondent_id: localStorage.getItem('uuid')                
        //     }

        //     if(this.form.notifications.respondent) notification.respondent_email = this.getRespondentEmail()

        //     // this.notifyFormComplete(notification)
        // },

        // notifyFormComplete(formData){
        //     return true
        //     this.$axios
        //         .post(process.env.VUE_APP_API_URL + 'answer/complete', formData)                
        //         .then(response => {
        //             if(response){
        //                 // this.$trackEvent.mixpanel("Notification Sent", {distinct_id: this.$parent.form.cus, form: this.form.slug, notify: {owner: this.form.notifications.owner, respondent: this.form.notifications.respondent}})
        //             }
        //         })            
        //         .catch(error => console.log(error))
        // },

        hasAnyAnswer(){
            return Object.values(this.variables).some((value) =>{
                if(value !== null && value !== "" && value !== undefined) return true
            })
        },

        getRespondentEmail(){
            let email = null
            this.form.fields.forEach(field => {                
                if(field.type == "email") email = this.variables[field.slug]
            })
            return email
        },

        watchKeyPress(){
            let self = this

            if(!this.demo){
                window.addEventListener('keyup', function(event) {
                    if(event.key === 'Enter' && event.shiftKey !== true) self.$refs.field[self.current_field].submit()
                });
            }
        },

        setVariable(variable){
            this.$set(this.variables, variable.slug, this.formatAnswerText(variable))
        },

        prevQuestion() {
            if(this.current_field == 0) return null
            if(this.form.fields[this.current_field]?.history !== null) this.current_field = this.form.fields[this.current_field].history
            else this.current_field = this.current_field - 1
            this.setFocus(this.current_field)
        },

        nextQuestion() {
            const nextQuestion = this.findNextQuestion()
            if(this.form.fields[nextQuestion].type == 'thankyou') this.endForm(this.form.fields[nextQuestion])
            if(nextQuestion < this.form.fields.length){
                this.form.fields[nextQuestion].history = this.current_field
                this.current_field = nextQuestion
                this.setFocus(this.current_field)
            }
        },


        changeQuestion(direction){
            if(direction == "prev") this.prevQuestion()
            if(direction == "next") this.submitField()
        },

        findNextQuestion(fieldAnswer = null){
            const hasLogic = this.hasLogic(this.form.fields[this.current_field])
            let nextQuestion

            if(hasLogic){
                nextQuestion = this.getNextQuestionByLogic(this.form.fields[this.current_field], fieldAnswer)                
            }

            if(!nextQuestion) return this.current_field + 1
            return nextQuestion

        },

        hasLogic(field){
            if(!field.logic.active) return null
            if(field.logic.actions.length == 0) return null
            if(!this.fieldHasLogic(field.type)) return null
            return true
        },

        getCurrentAnswerValue(){
            return this.variables[this.form.fields[this.current_field].slug]
        },

        getNextQuestionByLogic(field, current_answer = null){
            if(!current_answer) current_answer = this.getCurrentAnswerValue()
            const actions = field.logic.actions

            if(this.isEmpty(current_answer) && actions[0].condition[0].type != 'empty') return null
            const current_answer_lowercase = !this.isEmpty(current_answer) ? current_answer.toString().toLowerCase() : ''
            let result

            actions.some(action => {
                const conditionType = action.condition[0].type
                const condition = action.condition[0].data
                const enter = condition.enter?.toString().toLowerCase()
                const enter2 = condition.enter2?.toString().toLowerCase()
                if(conditionType == 'empty') result = this.answerIsEmpty(current_answer_lowercase, condition.exit)
                if(conditionType == 'any') result = this.answerContainAny(current_answer_lowercase, enter, condition.exit)
                if(conditionType == 'is') result = this.answerIs(current_answer_lowercase, enter, condition.exit)
                if(conditionType == 'not_is') result = this.answerNotIs(current_answer_lowercase, enter, condition.exit)
                if(conditionType == 'contain') result = this.answerContain(current_answer_lowercase, enter, condition.exit)
                if(conditionType == 'not_contain') result = this.answerNotContain(current_answer_lowercase, enter, condition.exit)
                if(conditionType == 'bigger_then') result = this.answerBiggerThen(current_answer_lowercase, enter, condition.exit)
                if(conditionType == 'lower_then') result = this.answerLowerThen(current_answer_lowercase, enter, condition.exit)
                if(conditionType == 'date_is') result = this.answerDateIs(current_answer_lowercase, enter, condition.exit)
                if(conditionType == 'date_before') result = this.answerDateBefore(current_answer_lowercase, enter, condition.exit)
                if(conditionType == 'date_after') result = this.answerDateAfter(current_answer_lowercase, enter, condition.exit)
                if(conditionType == 'score_bigger_then') result = this.answerScoreBiggerThen(this.score.local, enter, condition.exit)
                if(conditionType == 'score_between') result = this.answerScoreBetween(this.score.local, [enter, enter2], condition.exit)
                if(result) return result               
            })

            return result

        },

        answerScoreBetween(current_answer, enter, exit){
            if(current_answer >= enter[0] && current_answer <= enter[1]) return this.findQuestionIndexBySlug(exit)
        },

        answerScoreBiggerThen(current_answer, enter, exit){
            if(current_answer > enter) return this.findQuestionIndexBySlug(exit)
        },

        answerDateIs(current_answer, enter, exit){            
            if(this.compareDates(current_answer, enter) == 'equal') return this.findQuestionIndexBySlug(exit)            
        },

        answerDateBefore(current_answer, enter, exit){            
            if(this.compareDates(current_answer, enter) == 'before') return this.findQuestionIndexBySlug(exit)            
        },

        answerDateAfter(current_answer, enter, exit){            
            if(this.compareDates(current_answer, enter) == 'after') return this.findQuestionIndexBySlug(exit)            
        },

        compareDates(value1, value2){
            const answer = value1.split('/');            
            let date1 = new Date(Date.UTC(answer[2], answer[1] - 1, answer[0])) 
            let date2 = new Date(value2)
            if(!this.isDate(date1) || !this.isDate(date2)) return
            
            date1 = date1.getTime()
            date2 = date2.getTime()

            if(date1 === date2) return "equal"
            if(date1 > date2) return "after"
            if(date1 < date2) return "before"   

        },

        answerIsEmpty(current_answer, exit){
            if(this.isEmpty(current_answer)) return this.findQuestionIndexBySlug(exit)
        },

        answerContainAny(current_answer, enter, exit){
            if(current_answer.length > 0) return this.findQuestionIndexBySlug(exit)
        },

        answerIs(current_answer, enter, exit){
            if(enter == current_answer) return this.findQuestionIndexBySlug(exit)
        },
        answerNotIs(current_answer, enter, exit){
            if(enter != current_answer) return this.findQuestionIndexBySlug(exit)
        },
        answerContain(current_answer, enter, exit){
            if(current_answer.includes(enter)) return this.findQuestionIndexBySlug(exit)
        },

        answerNotContain(current_answer, enter, exit){
            const words = current_answer.split(" ")
            if(!words.includes(enter)) return this.findQuestionIndexBySlug(exit)
        },

        answerLowerThen(current_answer, enter, exit){ 
            if(this.isNumber(current_answer) === false || this.isNumber(enter) === false) return null           
            if(Number(current_answer) < Number(enter)) return this.findQuestionIndexBySlug(exit)
        },

        answerBiggerThen(current_answer, enter, exit){            
            if(!this.isNumber(current_answer) || !this.isNumber(enter)) return null            
            if(Number(current_answer) > Number(enter)) return this.findQuestionIndexBySlug(exit)
        },

        findQuestionIndexBySlug(slug){
            return this.form.fields.findIndex(function(person) {
                return person.slug == slug
            });

        },

        submitField(){
            this.$refs.field[this.current_field].submit()
        },

        submit(answer){
            this.trackAppsEvents("SubmitAnswer", answer)
            this.nextQuestion()
        },

        setFocus(index){
            const element = this.$refs.field[index].$el.querySelector(".field-text")

            if(element){
                // Delay to fix bug on Android
                setTimeout(() => {
                    element.focus({
                        preventScroll: true
                    })
                }, 100);
            } 

        },

        trackAppsEvents(event = "PageView", answer = null){
            if(!this.form) return 

            // Track Facebook Pixel
            if(this.form.apps.fbPixel.active && this.form.apps.fbPixel.value){                
                if(event == "PageView") this.$trackEvent.fb(this.form.apps.fbPixel.value, event);
                if(event == "EndForm") this.$trackEvent.fb(this.form.apps.fbPixel.value, 'Respondi' + event);
                if(event == "SubmitAnswer" && answer?.value != null){
                    const data = {question: answer.field_title, question_id: answer.field_slug, form_id: answer.form_id}
                    this.$trackEvent.fb(this.form.apps.fbPixel.value, 'Respondi' + event, data);                
                } 
            }
            
            // Track Custom Google Analytics
            if(this.form.apps.gAnalytics.active && this.form.apps.gAnalytics.value){                
                if(event == "PageView") this.$trackEvent.gaCustom(this.form.apps.gAnalytics.value, event);
                if(event == "EndForm") this.$trackEvent.gaCustom(this.form.apps.gAnalytics.value, "Respondi", "EndForm");
                if(event == "SubmitAnswer" && answer?.value != null) this.$trackEvent.gaCustom(this.form.apps.gAnalytics.value, "Respondi", "SubmitAnswer");
            }
          
        }

    },
        
    }
</script>

<style lang="sass" scoped>

.field-container-item,
.form-logo
    padding: $mgSm

.preview-container
    display: grid
    grid-template-columns: auto 50px
    grid-template-rows: 50px auto
    grid-template-areas: "header header" "content navbar"
    height: 100%
    width: 100%
    color: rgba($cFormText, 1)
    // background-color: $cFormBackground
    // background-image: $formBgImage
    background-size: cover
    background-position: 0 0
    transition: all 1s 1s

    .end-form
        overflow: hidden

    & [data-endform]
        width: calc(100% + 50px)
        margin-top: -50px
        height: calc(100% + 50px)        
        transition: all 1s


.header-container
    grid-area: header
    display: flex    
    flex-direction: column

    & [data-endform]
        transition: all .3s
        margin-top: -50px



    &[data-logo-position="left"]
        .form-logo
            justify-content: flex-start
            align-self: flex-start

    &[data-logo-position="center"]
        .form-logo
            justify-content: center
            align-self: center

    &[data-logo-position="right"]
        .form-logo
            justify-content: flex-end
            align-self: flex-end

    .form-logo
        display: flex
        max-width: 140px
        max-height: 140px



    .progressbar
        width: 100%

.nav-container
    grid-area: navbar

    & [data-endform]
        marging-right: -60px
        transition: all .3s 1s


    &.inative
        opacity: 0
        pointer-events: none
        transition: all .3s

        

.fields-container
    grid-area: content
    display: flex
    flex-direction: column
    justify-content: flex-start;
    height: 100%
    position: relative


.field-container-item,
.end-form
    min-height: 100%
    max-height: 100%
    overflow: auto
    opacity: 0
    pointer-events: none
    position: absolute
    top: 0
    left: 0
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    // justify-content: center
    transition: all .6s
    top: 60px

    &.exit
        top: -60px

    &.active
        top: 0  
        transition: all .6s
        opacity: 1
        pointer-events: auto

.end-form
    opacity: 1

[data-endform="true"]
    &.preview-container
        margin-top: -110px



</style>